<template>
  <div class="wrapper">
  <headBack>
    <template v-slot:title>
      <div class='title'>
        售后详情
      </div>
    </template>
  </headBack>
  <div class='header_cont'></div>
    <div class="wl_box">
      <div class="list">售后方式 ：  <span class="txt">{{detail.afterSaleModeName}}</span></div>
      <div class="dhao list"><div>售后编号 ：  <span  class="txt">{{ detail.afterSaleId }}</span></div> <div class="copy" @click="copy(detail.afterSaleId)">复制</div> </div>
      <div class="list jdu">
           <div>售后进度 ：</div>
           <div>
             <van-steps direction="vertical" :active="0">
               <van-step v-for="(item,index) in detail.afterSaleProcess">
                 <h3>{{item.processDesc}}</h3>
               </van-step>
             </van-steps>
           </div>
      </div>
    </div>
      <div class="list_box">
        <div class="top">
          <div>售后商品</div>
          <div class="timer"></div>
        </div>
        <van-card
            v-for="(item,index) in detail.orderData"
            :num="item.goodsNum"
            :price="item.goodsPrice"
            desc=""
            :title="item.goodsName"
            :thumb="item.iconUrl"
        />

      </div>
      <div class="p_box">
           <div class="tit">申请原因</div>
           <div class="txt">{{detail.afterSaleReasonName}}</div>
           <div class="img">
             <van-image
                 v-for="(item,index) in detail.contentUrlList"
                 width="100"
                 height="100"
                 :src="item.picUrl"
             />
           </div>
      </div>
    <div class="p_box">
      <div class="tit">手机号码  <span class="phone">{{ detail.contentNumber }}</span></div>

    </div>

    <div class="wl_box" v-if="nologistics && detail.afterSaleModeId != 3">
      <div class="tit">退货物流</div>
      <div class="list">快递公司 ：  <span class="txt">{{ detail.returnLogistics[0].courierServicesCompany}}</span></div>
      <div class="dhao list"><div>快递单号 ：  <span  class="txt">{{detail.returnLogistics[0].courierServicesOrder}}</span></div> <div class="copy" @click="copy(detail.returnLogistics[0].courierServicesOrder)">复制</div> </div>
    </div>
    <div class="p_box" v-if="!nologistics && detail.afterSaleModeId != 3">
      <div class="tit">退货物流</div>
      <div class="kd_1">
        <div class="t">快递公司：</div>
        <van-field v-model="logisticsName" label="" placeholder="请输入快递公司名称" />
      </div>
      <div class="kd_2">
        <div class="t">运单号：</div>
        <van-field v-model="logisticsNum" label="" placeholder="请输入运单号" />
      </div>
    </div>
    <div class="wl_box" v-if="nologisticsMerchant">
      <div class="tit">商家物流</div>
      <div class="list">快递公司 ：  <span class="txt">{{detail.merchantLogistics[0].courierServicesCompany}}</span></div>
      <div class="dhao list"><div>快递单号 ：  <span  class="txt">>{{detail.merchantLogistics[0].courierServicesOrder}}</span></div> <div class="copy" @click="copy(detail.merchantLogistics[0].courierServicesOrder)">复制</div> </div>
    </div>

    <div>
      <van-button round type="success" v-if="detail.afterSaleModeId == 3" @click="cancel">撤销申请</van-button>
      <van-button round type="success" v-if="!detail.afterSaleModeId == 3" @click="save">保存快递信息</van-button>
    </div>
    </div>
</template>

<script>
import {setWxShareDate} from '@/libs/utils.js'
import {onMounted, ref, computed, reactive, watch} from "vue"
import {useRouter, useRoute} from 'vue-router'
import {useStore} from 'vuex'
import {Dialog,Toast} from 'vant'
import shopApi from '@axios/shop'
import loginApi from '@axios/login'
export default {
  name: "afterDetail",
  setup(){
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const detail = ref('')
    const nologistics = ref(false)
    const nologisticsMerchant = ref(false)
    const getDetail = ()=> {
      const params = {
        orderId : route.query.orderId,
        userId:store.state.uid
      }
      shopApi.afterSalesDetail(params).then((res) => {
        console.log(res)
        detail.value = res.data;
        nologisticsMerchant.value =  detail.value.merchantLogistics.length > 0 ? true : false
        nologistics.value =  detail.value.returnLogistics.length > 0 ? true : false
      })
    }
    getDetail();

    const copy= (txt)=> {
      let input = document.createElement("input"); // 创建input对象
      input.value = txt; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例

      Toast("复制成功")
    }

    const logisticsName = ref('')
    const logisticsNum = ref('')
    const save =()=> {//保存快递信息
      if(!logisticsName.value || !logisticsNum.value){
        Toast("请完整填写物流信息")
        return;
      }
      const params ={
        orderId:route.query.orderId,
        afterSaleId:detail.value.afterSaleId,
        courierServicesCompany:logisticsName.value,
        courierServicesOrder:logisticsNum.value
      }
      shopApi.afterSaleSaveExpressInfo(params).then((res)=> {

        console.log(res)
        if(res.data){
          Toast("保存成功")
          location.reload();
        }
      })

    }
    const cancel =()=> {//撤销申请
      const params ={
        orderId:route.query.orderId,
        afterSaleId:detail.value.afterSaleId,
      }
      shopApi.afterSaleCancel(params).then((res)=> {
        if(res.data){
          Toast("申请成功")
          router.push({
            name:'afterList'
          })
        }
      })
    }
    return {
      detail,
      copy,
      cancel,
      save,
      logisticsName,
      logisticsNum,
      nologistics,
      nologisticsMerchant
    }
  }
}
</script>
<style scoped lang="scss">
  .wrapper{
    min-height: 100vh;
    padding-bottom:30px;
    background: #f9f9f9;
  }
  .wl_box{
    border-radius: 16px;
    font-size: 30px;
    box-sizing: border-box;
    margin:28px auto;
    width:94%;
    color:#878888;
    background: #fff;
    padding:20px;
    .tit{
      font-weight: 500;
      margin-bottom: 20px;
      color:#333;
    }
    .list{
      margin-bottom: 14px;
    }
    .txt{
      color:#878888;
    }
    .dhao{
      display: flex;
      justify-content: space-between;
      .copy{
        color:#1d1e1e;
        font-size: 22px;
        border-radius: 20px;
        padding:4px 14px;
        background: #f2f3f3;
      }
    }
  }
  .jdu{
    display: flex;
    ::v-deep(.van-step){
      font-weight: normal;
      font-size: 24px;
    }
    ::v-deep(.van-step__circle){
      width: 20px;
      height: 20px;
    }
    ::v-deep(.van-step--finish){
      color:#878888;
    }
    ::v-deep(.van-step--vertical){
      font-weight: normal;
      padding:1px 0px;
      line-height: 20px;
    }
    ::v-deep(.van-step__icon--active, .van-step__icon--finish, .van-step__title--active, .van-step__title--finish){
      color:#00c291!important;
    }

  }

  .list_box{
    color:#323232;
    width: 94%;
    margin:0 auto 20px;
    background: #fff;
    border-radius: 20px;
    padding:20px 0px;
    .top{
      font-size: 30px;
      padding:0 30px;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      .timer{
        color:#ee0a24;
        display: flex;
        font-size: 26px;
        align-items: center;
      }
    }
    ::v-deep(.van-card){
      background: #fff;
      //border-radius: 20px;
    }

    ::v-deep(.van-button){
      border-radius: 8px;
      font-size: 26px;
    }
    ::v-deep(.van-card:not(:first-child)){
      margin-top: 0;
    }
    ::v-deep(.van-card:first-child){
      //border-radius: 20px 20px 0 0;
    }
    ::v-deep(.van-card:last-child){
      //border-radius:0 0 20px 20px ;
    }
    .total_box{
      margin:10px 0;
      padding-right: 30px;
      text-align: right;
      font-size: 28px;
      .jg{
        font-size: 34px;
      }
    }
    .btn_box{
      margin-top: 16px;
      display: flex;
      justify-content: flex-end;
      padding-right: 20px;
      button{
        border:1px solid #ccc;
        margin-left:10px;
        background: #FFFFFF;
        font-size: 28px;
        border-radius: 26px;
        padding:4px 18px;
      }
      .red{
        color:#ee0a24;
        border:1px solid #ee0a24;
      }
    }
  }
  .p_box{

    color:#333;
    width: 94%;
    margin: 0 auto 20px;
    font-size: 30px;
    background: #FFFFFF;
    border-radius: 16px;
    padding:20px;
    box-sizing: border-box;
    .tit{
      font-weight: 500;
      .phone{
        font-weight:normal;
        margin-left: 20px;
        color:#999999;
      }
    }
    .txt{
      text-indent: 2em;
      margin:20px 0;
      color:#999999;
      font-size: 28px;
    }
    .van-image{
      margin-right: 10px;
    }
    .kd_1,.kd_2{
      display: flex;
      align-items: center;
       .t{
         padding:10px 0 0 0;
        width: 200px;
      }
      ::v-deep(.van-cell){
        padding:10px 0 0 0;
      }
    }
  }
  ::v-deep(.van-button){
    display: block;
    margin: 50px auto;
    width: 700px;
    background: #00c291;
  }

</style>
